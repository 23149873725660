import { jsx, jsxs } from "react/jsx-runtime";
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
    //@ts-expect-error
    "app/entry.client.tsx"
  );
  import.meta.hot.lastModified = "1698839577000";
}
import { RemixBrowser } from "@remix-run/react";
import { hydrate } from "react-dom";
import { ClientProvider } from "@mantine/remix";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
hydrate(
  /* @__PURE__ */ jsxs(MantineProvider, { children: [
    /* @__PURE__ */ jsx(Notifications, { position: "bottom-center" }),
    /* @__PURE__ */ jsx(ClientProvider, { children: /* @__PURE__ */ jsx(RemixBrowser, {}) })
  ] }),
  document
);
